<template>
  <app-preloader style="position: relative; top:200px; z-index:100"></app-preloader>
</template>

<script>
import CoreApi from '../../api/core'
import NotifyService from '../../services/NotifyService'
import Preloader from '../../components/preloader/Preloader'

export default {
  name: 'ArticleByDocumentIdView',
  components: {
    appPreloader: Preloader
  },
  methods: {
    getArticle (articleDocumentId) {
      CoreApi().get('/article?view=minimal&filter_eq[documentId]=' + articleDocumentId)
        .then(response => {
          if (response.data.data.length > 0) {
            let article = response.data.data[0]
            response.data.data.forEach(item => {
              if (item.setting.status === 'published') {
                article = item
              }
            })
            this.$router.push('/article/' + article.id)
          } else {
            this.articleNotFoundAndRedirectToList(articleDocumentId)
          }
        })
        .catch(error => {
          console.log(error)
          this.articleNotFoundAndRedirectToList(articleDocumentId)
        })
    },
    articleNotFoundAndRedirectToList (articleDocumentId) {
      NotifyService.setErrorMessage('Článok s id ' + articleDocumentId + ' nebol nájdený.')
      this.$router.push('/article')
    }
  },
  created () {
    if (this.$route.params.id === undefined) {
      return
    }
    this.getArticle(this.$route.params.id)
  }
}
</script>
